import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";

import styles from "./Packs.module.css";

function Packs() {
  // const packagess = [
  //   {
  //     amount: "20.00",
  //     id: 1,
  //     packId: 9919210002,
  //     space: 2,
  //   },
  //   {
  //     amount: "35.00",
  //     id: 2,
  //     packId: 9919210003,
  //     space: 5,
  //   },
  //   {
  //     amount: "43.00",
  //     id: 3,
  //     packId: 9919210004,
  //     space: 7,
  //   },
  //   {
  //     amount: "50.00",
  //     id: 4,
  //     packId: 9919210005,
  //     space: 10,
  //   },
  //   {
  //     amount: "70.00",
  //     id: 5,
  //     packId: 9919210006,
  //     space: 20,
  //   },
  //   {
  //     amount: "74.82",
  //     id: 6,
  //     packId: 9919210007,
  //     space: 25,
  //   },
  //   {
  //     amount: "75.0",
  //     id: 7,
  //     packId: 9919210008,
  //     space: 50,
  //   },
  //   {
  //     amount: "80.0",
  //     id: 8,
  //     packId: 9919210009,
  //     space: 100,
  //   },
  // ];

  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const purchasePackage = (pack) => {
    navigate(`purchase/${pack.space}`, {
      state: pack,
    });
  };

  useEffect(() => {
    const getPacks = async () => {
      try {
        const email = "dev@jhorotek.com";
        const password = "Jhoro@123";
        const response = await fetch(
          `https://api.meghdrive.store/api/packages?email=${encodeURIComponent(
            email
          )}&password=${encodeURIComponent(password)}`
        );

        if (!response.ok) {
          setPackages([])
          throw new Error("Network response was not ok");
        }

        const result = await response.json();

        if (result.length > 0) {
          const convertedPackages = result.map((originalPackage) => {
            const { id, amount, offer_id } = originalPackage;
            const space = originalPackage.product_name.match(/\(([^)]+)\)/)[1];

            return {
              amount: parseFloat(amount).toFixed(2),
              id,
              packId: parseInt(offer_id, 10),
              space,
            };
          });
          // console.log(convertedPackages);
          setPackages(convertedPackages);
        } else setPackages([]);

        // console.log(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setPackages([])
        setLoading(false);
      }
    };

    getPacks();
  }, []);

  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.black}>Packages</h1>
        <p className={styles.desc}>
          The Best Deals and Biggest Discounts created Just for You
        </p>
      </div>

      {!loading && (
        <div className={styles.packageContainer}>
          {packages.length > 0 &&
            packages.map((data, index) => (
              <div className={styles.card} key={index}>
                <div className={styles.cardHeader}>
                  <FontAwesomeIcon icon={faCloud} />
                  <h3>Pack {data.id}</h3>
                </div>
                <div className={styles.cardBody}>
                  <h6 data-starting>Starting From</h6>
                  <h1>BDT {data.amount}*</h1>
                  <h6 data-month>Per Month</h6>
                  <h4>{data.space} of Storage</h4>
                </div>
                <div
                  className={styles.cardFooter}
                  onClick={() => purchasePackage(data)}
                >
                  Purchase
                </div>
              </div>
            ))}

          {packages.length === 0 && <p className={styles.notFound}>Sorry! No Package Found</p>}

          {/* ---- loop the design for 8 packs ---- */}
          {/* <div className={styles.card}>
          <div className={styles.cardHeader}>
            <FontAwesomeIcon icon={faCloud} />
            <h3>Pack 1</h3>
          </div>
          <div className={styles.cardBody}>
            <h6 data-starting>Starting From</h6>
            <h1>BDT 20.00*</h1>
            <h6 data-month>Per Month</h6>
            <h4>2GB of Storage</h4>
          </div>
          <div className={styles.cardFooter}>Purchase</div>
        </div> */}
        </div>
      )}
      {loading && <div className={styles.loadingSpinner}></div>}
      {/* <p className={styles.vatSD}>*VAT, SD & SC will be added</p> */}
      <p className={styles.vatSD}>*Including all Tax, VAT, SC</p>
    </>
  );
}

export default Packs;
