import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home/Home";
import Purchase from "./components/Purchase/Purchase";
import Layout from "./Layout/Layout";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicies from "./pages/PrivacyPolicies/PrivacyPolicies";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/*" element={<Layout />}>
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="pp" element={<PrivacyPolicies />} />
          <Route path="unsub" element={<Unsubscribe />} />

          <Route path="home" element={<Home />} />
          <Route path="home/purchase/:id" element={<Purchase />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
