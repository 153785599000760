import React, { useRef } from "react";
import Packs from "../Packs/Packs";

import DisplayImage from "../../assets/img12.png";

import styles from "./Home.module.css";

function Home() {
  const ref = useRef(null);

  const jumpToPackages = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftCol}>
          <h1 className={styles.black}>Home For Your</h1>
          <h1 className={styles.primary}> Memories</h1>
          <p className={styles.desc}>
            Securely back-up your photos & videos to cloud & enjoy them from any
            device
          </p>
          <button className={styles.btn} type="button" onClick={jumpToPackages}>
            Packages
          </button>
        </div>

        <div className={styles.rightCol}>
          <img src={DisplayImage} alt="logo" />
        </div>
      </div>

      <div ref={ref}>
        <Packs />
      </div>
    </>
  );
}

export default Home;
