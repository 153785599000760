import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faCalendarDays } from "@fortawesome/free-solid-svg-icons";

import DisplayImage2 from "../../assets/img22.png";
// import SuccessImg from "../../assets/success2.png";
import BD from "../../assets/bd.png";
import styles from "./Purchase.module.css";

function Purchase() {
  const urlParam = useLocation();
  const navigate = useNavigate();

  const packageInfo = urlParam.state;

  const ref = useRef(null);
  const checkRef = useRef(null);

  const [mobile, setMobile] = useState("");
  const [agree, setAgree] = useState(false);
  const [otp, setOtp] = useState("");
  const [currentForm, setCurrentForm] = useState("mobile"); //mobile otp success failure
  const [isLoading, setIsLoading] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);

  const checkboxHandler = () => {
    setAgree(!agree);
    checkRef.current.style.accentColor = "#F16522";
  };

  const handlePostRequest = async () => {
    try {
      const response = await fetch(
        "https://api.meghdrive.store/api/subscription-req",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "Accept": "*/*",
          },
          body: JSON.stringify({
            mobile: `88${mobile}`,
            package: packageInfo.packId,
            email: "dev@jhorotek.com",
            password: "Jhoro@123",

            // email: encodeURIComponent("dev@jhorotek.com"),
            // password: encodeURIComponent("Jhoro@123"),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Post request successful:", result);

      setIsLoading(false);
      setCurrentForm("success");
    } catch (error) {
      console.error("Error making post request:", error.message);

      setIsLoading(false);
      setCurrentForm("failure");
    }
  };

  // When the button is clicked
  const formSubmit = (ev) => {
    ev.preventDefault();
    if (/^(019|014)\d{8}$/.test(mobile)) {
      // console.log(`req OTP for mobile no. +88${mobile}`);
      // setCurrentForm("otp");
      // setCurrentForm("success");

      setIsLoading(true);
      handlePostRequest();
    } else alert("Provide a valid Banglalink mobile number");
  };

  const submitOTP = (ev) => {
    ev.preventDefault();
    console.log(`OTP is ${otp}`);
    setCurrentForm("success");
  };

  const backToHome = () => {
    navigate("/");
  };

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    // console.log(packageInfo);
    // return () => {
    //   second
    // }
  }, []);

  const size = 6;
  const validationPattern = /[0-9]{1}/;
  const arr = new Array(size).fill("-");

  const handleInputChange = (e, index) => {
    const elem = e.target;
    const val = e.target.value;

    // check if the value is valid
    if (!validationPattern.test(val) && val !== "") return;

    // change the value of the upper state using onChange
    const valueArr = otp.split("");
    valueArr[index] = val;
    const newVal = valueArr.join("").slice(0, 6);
    setOtp(newVal);

    //focus the next element if there's a value
    if (val) {
      const next = elem.nextElementSibling;
      next?.focus();
    }
  };

  const handleKeyUp = (e) => {
    const current = e.currentTarget;
    if (e.key === "ArrowLeft" || e.key === "Backspace") {
      const prev = current.previousElementSibling;
      prev?.focus();
      prev?.setSelectionRange(0, 1);
      return;
    }

    if (e.key === "ArrowRight") {
      const prev = current.nextSibling;
      prev?.focus();
      prev?.setSelectionRange(0, 1);
      return;
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const val = e.clipboardData.getData("text").substring(0, size);
    setOtp(val);
  };

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.leftCol}>
        <img src={DisplayImage2} alt="mobile" />
      </div>

      {currentForm === "mobile" && (
        <div className={styles.rightCol}>
          <div className={styles.header}>
            <h3 className={styles.black}>Welcome to Megh</h3>
            <h1 className={styles.primary}> Purchase Confirmation</h1>
            <h6 className={styles.desc}>Purchasing For</h6>
          </div>

          {!isLoading && (
            <form className={styles.inputForm}>
              <div>
                <label data-mobile-label>
                  Mobile Number <span data-red>*</span>
                </label>
                <div data-input-mobile-block>
                  <div data-with-flags>
                    <img src={BD} alt="mobile" />
                    <h6>+88</h6>
                  </div>
                  <input
                    type="text"
                    placeholder="Eg. 019XXXXXXXX"
                    maxLength={11}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
              </div>

              <div className={styles.packageDetails}>
                <div className={styles.left}>
                  <div>
                    <span data-icon>
                      <FontAwesomeIcon icon={faCloud} />
                    </span>
                    <span data-pack-name>
                      Pack {packageInfo.id} ({packageInfo.space} of Storage)
                    </span>
                  </div>
                  <div data-margin>
                    <span data-icon className={styles.calender}>
                      <FontAwesomeIcon icon={faCalendarDays} />
                    </span>
                    <span data-validity>30 Days Validity</span>
                  </div>
                </div>

                <div className={styles.right}>
                  <h1 data-amount>BDT {packageInfo.amount}*</h1>
                  <h6 className={styles.vat} data-margin>
                    *including VAT, SD & SC
                  </h6>
                </div>
              </div>

              <div className={styles.conditions}>
                <input
                  type="checkbox"
                  id="agree"
                  onChange={checkboxHandler}
                  ref={checkRef}
                />
                <label htmlFor="agree" data-agree>
                  I agree to{" "}
                  <a href="/terms" target="_blank" rel="noreferrer noopener">
                    Terms of Services
                  </a>{" "}
                  and{" "}
                  <a href="/pp" target="_blank" rel="noreferrer noopener">
                    Privacy Policy
                  </a>
                </label>
              </div>

              <hr />

              <button
                className={styles.btn}
                type="button"
                disabled={!agree}
                onClick={formSubmit}
              >
                Purchase Now
              </button>
            </form>
          )}

          {isLoading && <div className={styles.loadingSpinner}></div>}
        </div>
      )}

      {currentForm === "otp" && (
        <div className={styles.rightCol}>
          <div className={styles.header}>
            <h3 className={styles.black}>Welcome to Megh</h3>
            <h1 className={styles.primary}> Purchase Confirmation</h1>
            <h6 className={styles.desc}>
              A 6 digit OTP has been sent to {`+88${mobile}`}
            </h6>
          </div>

          <form className={styles.inputForm}>
            <div>
              <label data-mobile-label>
                Verification Code <span data-red>*</span>
              </label>
              <div data-input-otp-block>
                {arr.map((_, index) => {
                  return (
                    <input
                      key={index}
                      // className={className || `input input-bordered px-0 text-center`}
                      type="text"
                      inputMode="numeric"
                      // autoComplete="one-time-code"
                      pattern={validationPattern.source}
                      maxLength={6}
                      value={otp.at(index) ?? ""}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyUp={handleKeyUp}
                      onPaste={handlePaste}
                    />
                  );
                })}
              </div>
            </div>

            <p className={styles.vat}>This OTP is Valid For 5 Minutes</p>

            <hr />

            <button className={styles.btn} type="button" onClick={submitOTP}>
              Confirm Purchase
            </button>
          </form>
        </div>
      )}

      {currentForm === "success" && (
        <div className={styles.rightCol}>
          <div className={styles.header}>
            <h3 className={styles.black}>Welcome to Megh</h3>
            <h1 className={styles.primary}> Processing</h1>
            <h6 className={styles.desc}>
              Your request is under process. You will get a SMS soon on
              <br />
              <span className={styles.highlight}>{`+88${mobile}`}</span>
              <br />
              Please check your phone for further instructions
            </h6>
            {/* <h6 className={styles.desc}>Your purchase has been successful</h6> */}
          </div>

          <div className={styles.successLogo}>
            {/* <img src={SuccessImg} alt="mobile" /> */}

            <div className={styles.successAnimation}>&#10004;</div>
          </div>

          <button className={styles.btn} type="button" onClick={backToHome}>
            Back To Home
          </button>
        </div>
      )}

      {currentForm === "failure" && (
        <div className={styles.rightCol}>
          <div className={styles.header}>
            <h3 className={styles.black}>Welcome to Megh</h3>
            <h1 className={styles.primary}> Rejected</h1>
            <h6 className={styles.desc}>
              We regret to inform you that your request has been rejected by the
              server.
              <br />
              Please try again after sometime.
            </h6>
          </div>

          <div className={styles.successLogo}>
            <div className={styles.failAnimation}>&#10006;</div>
          </div>

          <button className={styles.btn} type="button" onClick={backToHome}>
            Back To Home
          </button>
        </div>
      )}
    </div>
  );
}

export default Purchase;
