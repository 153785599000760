import React from "react";
import styles from "./TermsAndConditions.module.css";

function TermsAndConditions() {
  return (
    <div className={styles.container}>
      <h3>LAST UPDATED: MAY 23, 2018</h3> <br />
      <br />
      <p>
        This Terms of Use Agreement is prepared and entered into in the English
        language only, whose language shall be controlling in all respects. Any
        translation of this Terms of Use Agreement into any other language are
        for reference only and shall have no legal or other effect. Any
        capitalized terms not defined in this Terms of Use Agreement shall have
        the meanings given in Funambol Privacy Statement. Funambol Inc., located
        at 1065 E. Hillsdale Blvd. #250, Foster City, CA 94404 USA, ("we" or
        "Funambol") maintains the Internet web sites at funambol.com, zefiro.me
        (collectively, the "Site", including any successor web sites). We offer
        the Services (as defined below) to you ("you" or "user") through the
        Site under the terms and conditions of this Terms of Use and any
        additional term provided to you while using the Services (the
        "Terms&Conditions").
      </p>{" "}
      <br />
      <p>
        By accessing the Site, using the Services, or registering with Zefiro,
        you agree to the terms of this Terms&Conditions and you agree that you
        must follow any policies made available to you within the Services. The
        Terms&Conditions below and any other rules or policies set forth on the
        Site (all of which are incorporated into this Terms&Conditions,
        including, without limitation to, the Privacy Policy) comprise the
        entire agreement between you and Funambol and supersede all prior
        agreements between us. Funambol may update this Terms&Conditions from
        time to time without notice to you. Funambol recommends that you review
        the Terms&Conditions on a regular basis. You may not use the Services
        and may not accept the Terms&Conditions if you are not of legal age to
        form a binding contract with Funambol; individuals under the age of 18
        are not permitted to use the Services without the supervision of a
        parent or legal guardian. You agree that you are solely responsible for
        (and that Funambol has no responsibility to you or to any third party
        for) any breach of your obligations under the Terms&Conditions and for
        the consequences of any such breach. If you do not agree to the modified
        terms for a Service, you should discontinue your use of that Service.
      </p>{" "}
      <br />
      <p>
        1.SERVICES Funambol will provide you with access to the Zefiro Service
        (which enables synchronization and/or access of Personal and Rich Media
        data from various sites and applications), developer applications
        services, email-related services and/or publisher content services (the
        "Service" or "Services"). Use of the Services is limited to personal
        purposes and not for distribution to, or use on behalf of, others.
        Personal Data are your personal information manager files (which include
        your electronic "rolodex", SMS/MMS, call logs, list of apps). Rich Media
        data are your picture(s), video(s), file(s) and legally acquired music.
        Once you sign up for the use of the Services, your account will be
        allocated a certain level of storage capacity determined by Funambol.
        You explicitly agree that Funambol, at its sole discretion, can change
        and adjust the storage capacity and the limit at any time without notice
        to you.
      </p>{" "}
      <br />
      <p>
        Unless explicitly stated otherwise, any new online services provided by
        Funambol or features that augment or enhance the current Services, shall
        be subject to the Terms&Conditions.
      </p>{" "}
      <br />
      <p>
        You are responsible for obtaining access to the Services, and you are
        responsible to pay any third party fees (such as Internet service
        provider, site or application fees, airtime charges or mobile
        operator/carrier data plan fees) associated with such access and use.
        Further, you are responsible for complying with the Terms of Use/Terms
        and Conditions and Privacy Policies of all other third party sites and
        applications which may be incorporated into the Services and from which
        you share your Content through the Services. In addition, you must
        provide and are responsible for all equipment necessary to access the
        Services. Please note that according to the United State Department of
        State regulations, resident or citizens of countries that are subject to
        the US State Department embargo are not eligible to use the service.
        Funambol reserves the right to delete without notification any account/s
        that are discovered in violation of this stipulation.
      </p>{" "}
      <br />
      <p>
        2. REGISTRATION When you register for the Services, you agree to: (a)
        provide true, accurate, current and complete information about yourself
        as prompted by the registration form (your "Account Data") and (b)
        maintain the accuracy and completeness of the Account Data.
      </p>{" "}
      <br />
      <p>
        3. PRIVACY STATEMENT Our privacy statement, which is located at
        http://www.funambol.com/privacy.html, describes our data protection
        policies and practices and is a part of this Terms&Conditions.
      </p>{" "}
      <br />
      <p>
        {" "}
        4. ACCOUNT, PASSWORD AND SECURITY You will choose a password upon
        completing the registration process. You are responsible for maintaining
        the confidentiality of your password, and are fully responsible for all
        activities that occur under your account. You agree to (a) immediately
        notify Funambol of any unauthorized use of your account or any other
        breach of security, and (b) ensure that you fully exit from your account
        at the end of each session. Funambol will not be liable (to you or any
        third party) for any loss or damage arising from or related to the
        unauthorized use of your account (unless such use is directly caused by
        Funambol's gross negligence).
      </p>{" "}
      <br />
      <p>
        5. MEMBER CONDUCT All information, data (including Account Data and
        Contacts Data, as those terms are defined in the Privacy Statement),
        text, software, music, sound, photographs, graphics, video, messages or
        other materials ("Content") that you upload, post, email, store,
        transmit or otherwise make available (collectively, "transmit") to or
        through the Services is solely your responsibility. You agree to not use
        the Services to: (a) transmit any Content that is unlawful, harmful,
        threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene,
        libelous, invasive of another's privacy or publicity rights, hateful, or
        racially, ethnically or otherwise objectionable; (b) harm minors in any
        way; (c) impersonate any person or entity, including, but not limited
        to, a Funambol employee, forum leader, guide or host, or falsely state
        or otherwise misrepresent your affiliation with a person or entity; (d)
        forge headers or otherwise manipulate identifiers in order to disguise
        the origin of any Content transmitted through the Service; (e) transmit
        any Content (such as inside information, intellectual property, or
        confidential information) that you do not have a right to transmit under
        any law or under contractual or fiduciary relationships; (f) transmit
        any unsolicited or unauthorized advertising, promotional materials,
        "junk mail", "spam", "chain letters", "pyramid schemes", or any other
        form of solicitation, except in those areas (such as shopping rooms)
        that are designated for such purpose; (g) transmit any material that
        contains software viruses or any other computer code, files or programs
        designed to interrupt, destroy or limit the functionality of any
        computer software or hardware or telecommunications equipment; (h)
        interfere with or disrupt the Services or servers or networks connected
        to the Services, or disobey any requirements, procedures, policies or
        regulations of networks connected to the Services. You acknowledge and
        agree that Funambol may preserve Content and may also disclose Content
        if required to do so by law or in the good faith belief that such
        preservation or disclosure is reasonably necessary to: (a) comply with
        legal process; (b) enforce the Agreement; (c) respond to claims that any
        Content violates the rights of third parties; or (d) protect the rights,
        property, or personal safety of Funambol, its users or the public. You
        understand and agree on the following uploading policy: (a) any content
        (for example, but not limited to document, music file, video, photo)
        larger than 2GB (two Gigabyte) will not be stored on the server; (b) you
        are entitled of an upload capacity of 20GB per calendar month; the
        service may prevent upload of more content when such a limit is reached;
        (c) Funambol reserves the right to block or prevent, in full or part,
        access to the service if abuse or misuse is detected. You understand
        that the Services may involve (a) transmissions of your Content over
        various networks; and (b) changes to your Content to conform and adapt
        to technical requirements of connecting networks or devices. You further
        understand that the cancellation policy, as described below, will not
        apply in the circumstances described in this section 5. Therefore you
        will not be able to recover any fees and/or data from Funambol.
      </p>{" "}
      <br />
      <p>
        6. COMPLIANCE WITH LAW You may use the Services only as permitted by
        law. You further agree to comply with (a) all applicable laws regarding
        online conduct and acceptable Content, and (b) all applicable export and
        re-export control laws and regulations, including, without limitation
        to, the United States export laws and regulations. You acknowledge that
        Funambol can remove infringing Content and can close the account without
        notice of repeat infringers in order to comply with the Digital
        Millennium Copyright Act.
      </p>{" "}
      <br />
      <p>
        7. INDEMNITY You agree to indemnify and hold Funambol, and its
        subsidiaries, affiliates, officers, agents, co-branders or other
        partners, and employees, harmless from any claim or demand, including
        reasonable attorneys' fees, made by any third party arising out of or
        related to any Content you transmit through the Services, your use of
        the Services, your connection to the Services, your violation of the
        Terms&Conditions, or your violation of any rights of another.{" "}
      </p>
      <br />
      <p>
        8. NO RESALE OF SERVICE You agree not to reproduce, duplicate, copy,
        sell, resell or exploit for any commercial purposes, any portion of the
        Services, use of the Services, or access to the Services.
      </p>{" "}
      <br />
      <p>
        9. GENERAL PRACTICES REGARDING USE AND STORAGE You acknowledge that
        Funambol may establish general practices and limits concerning use of
        the Services, including without limitation the maximum number of days
        that your Content will be retained by the Services, the maximum amount
        of data synchronization that may executed by an account on the Services
        over a given time, the maximum amount of data that may be stored by an
        account on the Services, and the maximum number of times (and the
        maximum duration for which) you may access the Services in a given
        period of time. You acknowledge that other sites and applications from
        which you share your Content may also establish limits in the foregoing
        ways and Funambol has no control over those sites’ or applications’
        limitations. You acknowledge that Funambol reserves the right to log off
        accounts that are inactive for a specified period of time. Specifically,
        free of charge accounts that are inactive for six months will be deleted
        without notice and account deletion policy will not be granted. You
        further acknowledge that Funambol reserves the right to limit the number
        of sign-ups at any time without notice to you.
      </p>{" "}
      <br />
      <p>
        10. TECHNICAL SUPPORT You understand that Funambol does not provide any
        technical support as part of the Services. However, you may refer to
        info@zefiro.me for inquires.
      </p>{" "}
      <br />
      <p>
        11. MODIFICATIONS TO SERVICES Funambol reserves the right at any time
        and from time to time to modify or discontinue, temporarily or
        permanently, the Services (or any part thereof) with or without notice.
        You agree that Funambol shall not be liable to you or to any third party
        for any modification, suspension or discontinuance of the Services. You
        further agree that Funambol shall not be liable to you or to any third
        party for any temporary or permanent loss of data.
      </p>{" "}
      <br />
      <p>
        12. TERMINATION You agree that Funambol, in its sole discretion, may
        terminate your password, account (or any part thereof) or use of the
        Services, and remove and discard any Content within the Services, for
        any reason or no reason. Funambol may also in its sole discretion and at
        any time discontinue providing the Services, or any part thereof, with
        or without notice. You agree that any termination of your access to the
        Services under any provision of this Terms&Conditions may be effected
        without prior notice, and acknowledge and agree that Funambol may
        immediately deactivate or delete your account and all related
        information and files in your account and/or bar any further access to
        such files or the Services. Further, you agree that Funambol shall not
        be liable to you or any third party for any termination of your access
        to the Services.
      </p>
      <br />
      <p>
        13. ADVERTISERS AND THIRD PARTY LINKS Your correspondence or business
        dealings with, or participation in promotions of, advertisers found on
        or through the Services, including payment and delivery of related goods
        or services, and any other terms, conditions, warranties or
        representations associated with such dealings, are solely between you
        and such advertiser. You agree that Funambol shall not be responsible or
        liable for any loss or damage of any sort incurred as the result of any
        such dealings or as the result of the presence of such advertisers on
        the Services. Because Funambol has no control over third-party sites and
        resources to which Funambol may provide links (including advertiser
        sites), you acknowledge and agree that Funambol is not responsible for
        the availability of such external sites or resources, and does not
        endorse and is not responsible or liable for any Content, advertising,
        products, or other materials on or available from such sites or
        resources. You further acknowledge and agree that Funambol shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with use of or
        reliance on any such Content, goods or services available on or through
        any such site or resource.{" "}
      </p>
      <br />
      <p>
        {" "}
        14. PROPRIETARY RIGHTS Using our Services does not give you ownership of
        any intellectual property rights in our Services or the content you
        access. You may not use content from our Services unless you obtain
        permission from its owner or are otherwise permitted by law. These
        Terms&Conditions do not grant you the right to use any branding or logos
        used in our Services. You are not allowed to remove, obscure, or alter
        any legal notices displayed in or along with our Services. You and your
        licensors will retain ownership of all right, title, and interest in and
        to the Content you transmit to or through the Services. Subject to the
        terms and conditions of this Terms&Conditions, you hereby grant to
        Funambol a worldwide, perpetual, and irrevocable license to reproduce,
        distribute, create derivative works of, display, and perform any and all
        Content you transmit to or through the Service as contemplated under
        this Agreement and for the purpose of Funambol fulfilling its
        obligations, exercising its rights under this Terms&Conditions and
        providing the functionality of the Services accordingly to the Services
        Privacy Policy. You acknowledge and agree that the Services and any
        necessary software used in connection with the Services or the Site
        ("Software") contain proprietary and confidential information that is
        protected by applicable intellectual property and other laws. You
        further acknowledge and agree that Content contained in sponsor
        advertisements or information presented to you through the Services or
        advertisers is protected by copyrights, trademarks, service marks,
        patents or other proprietary rights and laws. Except as expressly
        authorized by Funambol or advertisers, you agree not to modify, rent,
        lease, loan, sell, distribute or create derivative works based on the
        Services or the Software, in whole or in part. Funambol grants you a
        personal, non-transferable and non-exclusive right and license to use
        the object code of its Software incidental to your use of the Services;
        provided that you do not (and do not allow any third party to) copy,
        modify, create a derivative work of, reverse engineer, reverse assemble
        or otherwise attempt to discover any source code, sell, assign,
        sublicense, grant a security interest in or otherwise transfer any right
        in the Software. Furthermore, you agree that you may not make the
        Software available to any third party as part of any rental,
        time-sharing, service bureau, or ASP arrangement and that the use of the
        Software is limited to your personal access. You agree not to modify the
        Software in any manner or form, or to use modified versions of the
        Software, including (without limitation) for the purpose of obtaining
        unauthorized access to the Services. You agree not to access the
        Services by any means other than through the interface that is provided
        by Funambol for use in accessing the Services. Unless you have agreed
        otherwise in writing with Funambol, nothing in the Terms&Conditions
        gives you a right to use any Funambol's trade names, trademarks, service
        marks, logos, domain names, and other distinctive brand features.
      </p>
      <br />
      <p>
        15. SHARING YOUR CONTENT AND INFORMATION You own all of the content and
        information you post on the Funambol Site and you can control how it's
        shared through your settings. In addition: (a) For Content that is
        covered by intellectual property rights and licenses by other sites and
        applications, including, without limitation to, photos, videos, and Rich
        Media data ("IP content"), you specifically give us the following
        permission: you grant us a non-exclusive, transferable, sub-licensable,
        royalty-free, worldwide license to use, copy, and make available any IP
        content that you post on or in connection with Funambol ("IP License").
        This IP License ends when you delete your IP content or your account
        unless you shared your content with others, and they have not deleted
        it. (b) When you delete IP content, it is deleted in a manner similar to
        emptying the recycle bin on a computer. However, you understand that
        removed content may persist in backup copies for a reasonable period of
        time (but will not be available to others) with up to 40 days after the
        deletion of the account. When you share Content and/or other information
        with other sites or applications other than the Funambol Site, you
        acknowledge that Funambol and the other sites and applications are
        independent from one another. You further acknowledge and agree that
        once you decide to share Content and/or other information with or from
        other sites or applications, you must also comply with the terms and
        conditions and privacy policies of those other sites and applications.{" "}
      </p>
      <br />
      <p>
        16. DISCLAIMER OF WARRANTIES YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
        (a) YOUR USE OF THE SITE AND THE SERVICES IS AT YOUR SOLE RISK.THE SITE
        AND THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
        FUNAMBOL EXPRESSLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES, AND
        CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
        LIMITED TO THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR
        A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, ACCURACY, AND QUIET
        ENJOYMENT. (b) FUNAMBOL MAKES NO WARRANTY (AND ASSUMES NO OBLIGATION)
        THAT (i) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
        ERROR-FREE, (ii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
        SERVICES WILL BE ACCURATE OR RELIABLE, (iii) ANY ERRORS IN THE SOFTWARE
        WILL BE CORRECTED, (iv) ANY SOFTWARE DOWNLOADED TO A MOBILE DEVICE, OR
        ANY INFORMATION READ FROM OR WRITTEN TO A MOBILE DEVICE, WILL NOT
        ADVERSELY AFFECT THE PERFORMANCE, OPERATION, WARRANTY OR ANY OTHER
        ASPECT OF THE DEVICE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
        OBTAINED BY YOU FROM FUNAMBOL OR THROUGH OR FROM THE SERVICE SHALL
        CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS&CONDITIONS.
      </p>
      <br />
      <p>
        17. LIMITATION OF LIABILITY WHEN PERMITTED BY LAW, IN NO EVENT SHALL
        FUNAMBOL BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
        CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO,
        DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
        LOSSES (EVEN IF FUNAMBOL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES), RESULTING FROM OR RELATED TO THIS AGREEMENT, INCLUDING WITHOUT
        LIMITATION: (i) THE USE OR THE INABILITY TO USE THE SERVICES; (ii)
        UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
        (iii) LOSS OR DELETION OF ANY OF YOUR DATA OR CONTENT (iv) STATEMENTS,
        CONDUCT, OR CONTENT OF ANY THIRD PARTY ON THE SERVICE OR THE SITE; OR
        (v) ANY OTHER MATTER RELATING TO THE SERVICE. TO THE EXTENT PERMITTED BY
        LAW, THE TOTAL LIABILITY OF FUNAMBOL FOR ANY CLAIM UNDER THESE
        TERMS&CONDITIONS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO
        THE AMOUNT PAID US TO USE THE SERVICES. IN ALL CASES, FUNAMBOL WILL NOT
        BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.
      </p>{" "}
      <br />
      <p>
        {" "}
        18. EXCLUSIONS AND LIMITATIONS SOME JURISDICTIONS DO NOT ALLOW THE
        EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
        LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.ACCORDINGLY, SOME OF
        THE ABOVE LIMITATIONS OF SECTIONS 16 AND 17 MAY NOT APPLY TO YOU.
      </p>{" "}
      <br />
      <p>
        19. COPYRIGHTS Funambol respects the intellectual property of others,
        and we ask our users to do the same. If you believe that your work has
        been copied in a way that constitutes copyright infringement, or your
        intellectual property rights have been otherwise violated, provide the
        following information to Funambol: an electronic or physical signature
        of the person authorized to act on behalf of the owner of the copyright
        or other intellectual property interest; a description of the
        copyrighted work or other intellectual property that you claim has been
        infringed; a description of where the material that you claim is
        infringing is located on the site; your address, telephone number, and
        email address; a statement by you that you have a good faith belief that
        the disputed use is not authorized by the copyright owner, its agent, or
        the law; a statement by you, made under penalty of perjury, that the
        above information in your Notice is accurate and that you are the
        copyright or intellectual property owner or authorized to act on the
        copyright or intellectual property owner's behalf. Intellectual property
        infringement claim can be sent to info@funambol.com. You further
        acknowledge and agree not to use in connection with the Services any
        copyrighted material without the written consent of the owner of such
        material. The unauthorized upload of copyrighted or other proprietary
        Content is illegal and could subject you to personal liability for
        damages in a civil suit as well as criminal prosecution. You assume all
        liability for any damage resulting from any infringement of copyright or
        proprietary rights.
      </p>{" "}
      <br />
      <p>
        20. TO U.S. GOVERNMENT END USERS ONLY The Software is a “commercial
        item” as that term is defined at 48 C.F.R. 2.101 (OCT 1995), and more
        specifically is “commercial computer software” and “commercial computer
        software documentation,” as such terms are used in 48 C.F.R. 12.212
        (SEPT 1995). Consistent with 48 C.F.R. 12.212 and 48 C.F.R. 227.7202-1
        through 227.7202-4 (JUNE 1995), the software is provided to U.S.
        Government End Users (a) only as a commercial end item and (b) with only
        those rights as are granted to all other End Users pursuant to the terms
        and conditions herein.
      </p>{" "}
      <br />
      <p>
        21. GENERAL The Terms&Conditions and the relationship between you and
        Funambol shall be governed by the laws of the State of California
        (without giving effect to any principles that may provide for the
        application of the law of another jurisdiction). You agree to submit to
        the exclusive jurisdiction of the courts located within the county of
        Santa Clara, California. The failure of Funambol to exercise or enforce
        any right or provision of the Terms&Conditions shall not constitute a
        waiver of such right or provision. If any provision of the
        Terms&Conditions is found by a court of competent jurisdiction to be
        invalid, the parties nevertheless agree that the court should endeavor
        to give effect to the parties' intentions as reflected in the provision,
        and the other provisions of the Terms&Conditions remain in full force
        and effect. You agree that regardless of any statute or law to the
        contrary, any claim or cause of action arising out of or related to use
        of the Services or the Agreement must be filed within one (1) year after
        such claim or cause of action arose or be forever barred. The section
        titles in the Terms&Conditions are for convenience only and have no
        legal or contractual effect. Any notices intended to have legal effect:
        (a) to you may be made via email, regular mail or through the Services,
        and (b) to Funambol must be made by delivery receipt-requested mail at
        Funambol headquarters, ATTN: Legal Department.
      </p>
    </div>
  );
}

export default TermsAndConditions;
