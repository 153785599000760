import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../assets/logo2.png";
import FooterImage from "../assets/img32.png";

import styles from "./Layout.module.css";

function Layout() {
  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.headerContent}>
          <img
            src={Logo}
            alt="logo"
            className={styles.logo}
            onClick={backToHome}
          />
        </div>
      </header>

      <div className={styles.dynamicContent}>
        <div>
          <Outlet />
        </div>
      </div>

      <footer>
        <div className={styles.footer}>
          <div className={styles.iconContainer}>
            <div className={styles.iconBG}>
              <FontAwesomeIcon icon={faFacebookF} />
            </div>
            <div className={styles.iconBG}>
              <FontAwesomeIcon icon={faTwitter} />
            </div>
            <div className={styles.iconBG}>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </div>
            <div className={styles.iconBG}>
              <FontAwesomeIcon icon={faYoutube} />
            </div>
          </div>

          <div className={styles.imageContainer}>
            <img src={FooterImage} alt="logo" />
          </div>

          <div className={styles.footerLinks}>
            <a href="/unsub" target="_blank" rel="noreferrer noopener">
              Unsubscribe
            </a>
            <a href="/terms" target="_blank" rel="noreferrer noopener">
              Terms of Services
            </a>
            <a href="/pp" target="_blank" rel="noreferrer noopener">
              Privacy Policy
            </a>

            <p> Copyright & Design © 2023 jhoroTEK</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
