import React from 'react';

import styles from "./Unsubscribe.module.css";

function Unsubscribe() {
  return (
    <div className={styles["table-container"]}>
        <h3>To unsubscribe, please follow these instructions:</h3>
        <table>
          <thead>
            <tr>
              <th className={styles["fixed-column"]}>Package</th>
              <th>Unsubscription Instructions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles["fixed-column"]}>
                <strong>
                  Pack 1 (2 GB of Storage)
                </strong>
              </td>
              <td>
                <p>Go to your phone's message section and write <strong>STOP P1</strong> then send it to the number <strong>29957</strong>.</p>
              </td>
            </tr>
            <tr>
              <td className={styles["fixed-column"]}>
                <strong>
                  Pack 2 (5 GB of Storage)
                </strong>
              </td>
              <td>
                <p>Go to your phone's message section and write <strong>STOP P2</strong> then send it to the number <strong>29957</strong>.</p>
              </td>
            </tr>
            <tr>
              <td className={styles["fixed-column"]}>
                <strong>
                Pack 3 (7 GB of Storage)
                </strong>
              </td>
              <td>
                <p>Go to your phone's message section and write <strong>STOP P3</strong> then send it to the number <strong>29957</strong>.</p>
              </td>
            </tr>
            <tr>
              <td className={styles["fixed-column"]}>
                <strong>
                Pack 4 (10 GB of Storage)
                </strong>
              </td>
              <td>
                <p>Go to your phone's message section and write <strong>STOP P4</strong> then send it to the number <strong>29957</strong>.</p>
              </td>
            </tr>
            <tr>
              <td className={styles["fixed-column"]}>
                <strong>
                Pack 5 (20 GB of Storage)
                </strong>
              </td>
              <td>
                <p>Go to your phone's message section and write <strong>STOP P5</strong> then send it to the number <strong>29957</strong>.</p>
              </td>
            </tr>
            <tr>
              <td className={styles["fixed-column"]}>
                <strong>
                Pack 6 (25 GB of Storage)
                </strong>
              </td>
              <td>
                <p>Go to your phone's message section and write <strong>STOP P6</strong> then send it to the number <strong>29957</strong>.</p>
              </td>
            </tr>
            <tr>
              <td className={styles["fixed-column"]}>
                <strong>
                Pack 7 (50 GB of Storage)
                </strong>
              </td>
              <td>
                <p>Go to your phone's message section and write <strong>STOP P7</strong> then send it to the number <strong>29957</strong>.</p>
              </td>
            </tr>
            <tr>
              <td className={styles["fixed-column"]}>
                <strong>
                Pack 8 (100 GB of Storage)
                </strong>
              </td>
              <td>
                <p>Go to your phone's message section and write <strong>STOP P8</strong> then send it to the number <strong>29957</strong>.</p>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
  )
}

export default Unsubscribe