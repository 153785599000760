import React from "react";
import styles from "./PrivacyPolicies.module.css";

function PrivacyPolicies() {
  return (
    <div className={styles.container}>
      <h3>LAST UPDATED: November 21, 2023</h3> <br />
      <br />
      <p>
        This Privacy Statement is prepared and entered into in the English
        language only, this language shall be controlling in all respects. Any
        translation of this Privacy Statement into any other language is for
        reference only and shall have no legal or other effect.
      </p>{" "}
      <br />
      <p>
        This Privacy Statement describes the data protection policies of
        Funambol, Inc., PO BOX 21563, San Jose, CA 95151 ("Funambol" or "we")
        for data collected through (1) funambol.com and zefiro.me (collectively,
        the "Site", including any successor web sites), and (2) any services
        (including without limitation to the Funambol portal service) provided
        through the Site or hosted for Funambol customers on Amazon Web Services
        data centers ("Service" or "Services"). The words “you” or “your” refer
        to a user of the Funambol services. “Funambol customers” refers to third
        party providers of the Funambol solutions.
      </p>{" "}
      <br />
      <p>
        {" "}
        Our Services may allow you access to your Contacts data, Rich Media
        data, text, software, legally acquired music, sound, photographs,
        graphics, video, messages, call log, applications list or other
        materials ("Content") from third party sites and applications. Any
        Content that you provide on or to a third-party site or applications is
        provided directly to the owner of the site or application and is subject
        to that party's data protection policy. Our Privacy Statement does not
        apply to such third party sites or services and we are not responsible
        for the content, data protection or security practices and policies of
        those sites or applications. To protect your information we recommend
        that you carefully review the Privacy policies of other sites and
        applications that you access. Any capitalized terms not defined in this
        Privacy Statement shall have the meanings given in Funambol Terms of
        Use.
      </p>
      <br />
      <p>
        With this Statement, we hereby make you aware that your personal data
        (e.g. Contacts Data, Rich Media Data and other content) provided through
        the Site or hosted for Funambol customers is stored in the EU located
        Amazon Web Services data centers.
      </p>
      <br />
      <p>
        We might transfer data outside European Union where such information is
        useful to provide end users support, to handle payments related to
        customer purchases and for analytics purposes. This information might
        include minimized and pseudonymized personal information, properly
        protected by adequate technical measures and agreed under approved
        Standard Contractual Clauses.
      </p>{" "}
      <br />
      <p>
        Funambol complies with the EU-U.S. Data Privacy Framework (EU-U.S. DPF),
        the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy
        Framework (Swiss-U.S. DPF) as set forth by the U.S. Department of
        Commerce. Funambol has certified to the U.S. Department of Commerce that
        it adheres to the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF
        Principles) with regards to the processing of personal data received
        from the European Union in reliance on the EU-U.S. DPF and from the
        United Kingdom (and Gibraltar) in reliance on the UK Extension to the
        EU-U.S. DPF. Funambol has certified to the U.S. Department of Commerce
        that it adheres to the Swiss-U.S. Data Privacy Framework Principles
        (Swiss-U.S. DPF Principles) with regards to the processing of personal
        data received from Switzerland in reliance on the Swiss-U.S. DPF. To
        ensure its compliance with the Data Privacy Framework, Funambol is
        subject to the investigatory and enforcement powers of the Federal Trade
        Commission (the “FTC”).
      </p>{" "}
      <br />
      <p>
        If there is any conflict between the terms in this privacy policy and
        the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles, the
        respective Principles shall govern the policy. To learn more about the
        Data Privacy Framework (DPF) program, and to view our certification,
        please visit https://www.dataprivacyframework.gov.
      </p>{" "}
      <br />
      <p>
        In compliance with GDPR requirements and with DPF Principles, Funambol
        commits to answer questions or doubts and to resolve complaints about
        our collection or use of your Personal Data. Individuals with inquiries
        or complaints regarding our data protection policy should first contact
        Funambol by emailing dataprotection@funambol.com.
      </p>
      <br />
      <p>
        Funambol has designated Funambol srl as its Representative in the EU.
        Funambol srl acts on behalf of Funambol Inc with regards to all
        obligations under the relevant EU Data Protection Regulation and acts as
        a point of contact for requests by data subjects and in particular for
        the assurance of user rights. Funambol srl, located in Italy (Via F.lli
        Cuzio, 42 - 27100 Pavia (PV), VAT number 01982880187), can be reached by
        email at dataprotection@funambol.com. Funambol adheres to an independent
        recourse for investigating unresolved complaints regarding Data Privacy
        Framework (DPF) commitments as prescribed by JAMS at
        http://www.jamsadr.com/adr-spectrum/. If you do not receive timely
        acknowledgment of your complaint from us or if we have not addressed
        your complaint to your satisfaction, please visit JAMS for more
        information or to file a complaint. If your complaint has not been
        resolved by JAMS, under certain conditions, it is possible to seek
        recourse through binding arbitration. To learn more about Data Privacy
        Framework and to view Funambol's certification, visit
        https://www.dataprivacyframework.gov.
      </p>{" "}
      <br />
      <p>
        {" "}
        By submitting any information or registering for any Funambol Services,
        you agree (1) to comply with the terms and conditions of this Privacy
        Statement, and (2) that Funambol may process (i.e., collect, use, etc.)
        your Personal Data as described in this Privacy Statement and the
        Funambol Terms of Use.
      </p>
      <br />
      <p>
        1. INFORMATION COLLECTION AND USE 1.1 Generally As used in this Privacy
        Statement, Contacts Data are your personal information manager files
        (which include your electronic "rolodex" and calendar). Rich Media data
        are your picture(s), video(s), file(s), music, SMSs, call log and
        applications list. Funambol allows you to submit Personal Data through
        the Site and the Services. The Funambol Service enables you to upload
        your Contacts Data and Rich Media Data to the Service and to synchronize
        your Contacts Data and Rich Media Data to and from other data stores
        such as a cellphone, PC, email server, social network site, etc.
        "Personal Data" is information about you that is personally identifiable
        to you as well as other non-public information to the extent that it is
        associated with the foregoing.{" "}
      </p>
      <br />
      <p>
        1.2 Registration When you register with Funambol, you submit the
        following types of Personal Data to Funambol (your "Account Data"): your
        mobile phone number, your email address, and your chosen password. This
        information will be kept in our archives for all the duration of the
        Services provision. Please note that according to the United State
        Department of State regulations, resident or citizens of countries that
        are subject to the US State Department embargo are not eligible to use
        the service. Funambol reserves the right to delete without notification
        any account/s that are discovered in violation of this stipulation.
      </p>{" "}
      <br />
      <p>
        1.3 Funambol Service, Contacts Data and Rich Media Data When you use the
        Funambol Service, you upload or synchronize your Contacts Data and/or
        Rich Media Data to Funambol's (or our service provider's) servers. We do
        not use your Contacts Data and/or your Rich Media Data for any purpose
        other than to provide you the Funambol Service. The legal basis for
        processing is the performance of the Services as described in the Terms
        Of Use. This information will be kept in our archives for all the
        duration of the Services provision. If at any time you no longer wish to
        use the service for any reason, including you do not want Funambol or
        third parties to use your Personal Data, you can terminate your account
        per Section 5 below.
      </p>
      <br />
      <p>
        {" "}
        1.4 Use of Account Data We may use your Account Data for: license
        reporting and assessment of service levels; to better understand how our
        Site and Services are used; to gauge traffic patterns and determine what
        types of content and services are most popular with users (as per the
        legitimate interest of the Data Controller) to contact you with
        information about Funambol products, services, news and events (as per
        the legitimate interest of the Data Controller. However, all users are
        always presented with the option to not receive these types of
        communications)
      </p>
      <br />
      <p>
        1.5 Cookies and Log Files We use information that we collect through
        cookies and log files to understand our users ' preferences and to
        improve the Site and the Services. A cookie is a piece of data stored on
        the user's computer tied to information about the user. We use both
        session ID cookies and persistent cookies. For the session ID cookie,
        once users close the browser, the cookie simply terminates. A persistent
        cookie is a small text file stored on the user's hard drive for an
        extended period of time. We use cookies to save the user's session and
        to carry out other activities that are strictly necessary for the
        operation of the same. No consent is required for the usage of these
        cookies, as per they are intended only for technical purposes. Anyway,
        persistent cookies can be removed by following Internet browser help
        file directions. If you choose to disable cookies, some areas of the
        Site and the Services may not work properly. In addition we use Google
        Analytics cookies with IP address anonymization to have an overall view
        of performance statistics, visitor habits and to improve the overall
        experience. In our log files, for security and troubleshooting reasons
        (legitimate interest of the Data Controller), we collect information
        such as internet protocol ("IP") addresses, browser type, internet
        service provider ("ISP"), referring/exit pages, platform type, date/time
        stamp, and number of clicks. Log file information may be linked to your
        Personal Data and are kept in our archives for 1 year.{" "}
      </p>
      <br />
      1.6 Feedback If you send Funambol an email or mail (to provide feedback or
      to ask a question regarding the Site or Services), Funambol will collect
      any information contained in such communication. Funambol may retain those
      communications in order to process your inquiries, respond to your
      requests, and improve the Services. Funambol will treat the Personal Data
      in such communication in accordance with this Privacy Statement. Funambol
      will keep this information for the duration of the Services provision.
      <br />
      <p>
        1.7 Anonymous Information Funambol may use any non-personally
        identifiable information that it collects or stores (including your
        feedback, but excluding your Account Data and your Contacts Data and
        Rich Media Data) for any purpose it deems appropriate.
      </p>{" "}
      <br />
      <p>
        1.8 Children Individuals under the age of 18 are not permitted to use
        the Services without the supervision of a parent or legal guardian;
        (iii) we do not knowingly collect or solicit Personal Data from children
        under the age of 18 or knowingly allow such persons to register for an
        online account or to post personal information through the Services; and
        (iv) should we learn that someone under the age of 18 has provided any
        Personal Data to the Services, we will remove that information as soon
        as possible.
      </p>
      <br />
      <p>
        1.9 Data subject's rights We remind you (the user) that you always have
        the right to access, rectify and erase your personal data, that you can
        manage your personal data on your own with your account and according to
        Section 5 below. You have also the right to restrict the processing and
        data portability of your data, and you can always opt-out of any consent
        you gave (with no prejudice for previous processing) and you have the
        right to lodge a complaint to the EU supervisory authority. In
        compliance with GDPR requirements and Data Privacy Framework Principles,
        you can raise inquiries, rights execution requests or complaints by
        emailing dataprotection@funambol.com.
      </p>{" "}
      <br />
      <p>
        2. COMMUNICATIONS 2.1 Special Offers and Updates We may send new members
        a welcoming email or SMS to verify password and username. Established
        members will occasionally receive information on products, services,
        special deals, and a newsletter, under Funambol’s legitimate interest of
        informing its customers about relevant changes or opportunities applied
        to the Services. We always present the option to not receive these types
        of communications.{" "}
      </p>
      <br />
      <p>
        2.2 Service Announcements We may send you service related announcements,
        including, for instance, when a Service is temporarily suspended for
        maintenance. Generally, users may not opt-out of these communications,
        though they can deactivate their account.
      </p>{" "}
      <br />
      <p>
        2.3 Tell-a-Friend If you decide to send a "tell-a-friend" email to
        another person (when Funambol provides such a feature), we will collect
        that person's email address in order to send the email. We will also
        collect your name, email address, and any personal message you decide to
        include in the email, for the purpose of sending the email with our
        systems. Unless we note that we may send reminder messages to that
        person, we will not send additional emails to that person. In addition,
        we provide the option to opt-out of such messages as provided in section
        2.1 above.
      </p>{" "}
      <br />
      <p>
        {" "}
        3. DISCLOSURE 3.1 Limited Data Sharing with Third Parties We may share
        your Personal Data with third parties in accordance with this Privacy
        Statement, only for the purpose of Services provision. We may need to
        provide your Personal Data to third parties to deliver specific services
        to you (such as hosting services or support services). These third
        parties are designated as Data Processors and are required not to
        disclose your Personal Data and not to use your Personal Data other than
        to provide the services requested by Funambol. We signed with all our
        Data Processors a dedicated Data Processing Agreement (DPA) that
        provides that your Personal Data may only be processed for the purposes
        and following the instructions mandates by Funambol as well as GDPR and
        Data Privacy Framework Principles. If a third party providing services
        on our behalf processes your Personal Data in a manner that violates
        GDPR or Data Privacy Framework Principles, the third party is liable for
        damages unless it is proven that Funambol is responsible for the event
        giving rise to the violation. You have control over synchronization of
        your Contacts Data and/or Rich Media Data through your account. Our
        servers will synchronize your Contacts Data and/or Rich Media Data with
        other devices, and may synchronize such data with third party
        applications, as instructed by you through your account. As noted in the
        Funambol Terms of Use, it is your responsibility to maintain the secrecy
        of your password; if another person obtains your password, that person
        may access and misuse your Contacts and/or Rich Media Data. Funambol may
        share some or all of your Personal Data with its affiliates, in which
        case we will require our affiliates to honor this Privacy Statement. If
        our company or our assets are acquired by another company, that company
        will possess the Personal Data collected by Funambol and it will assume
        the rights and obligations regarding your Personal Data as described in
        this Privacy Statement. We may share aggregated or non-personally
        identifiable information with our business partners but such information
        is not linked to your Personal Data and is not included in your Contacts
        Data.
      </p>{" "}
      <br />
      <p>
        3.2 Legal Requirements Funambol may disclose Personal Data, including,
        without limitation to, Contacts Data and Rich Media Data - if required
        by a subpoena or other judicial or administrative order, if required by
        law, or, at its sole discretion, if Funambol deems it necessary to
        prevent violation of the Terms of Use Agreement or the rights of
        Funambol or any third party.{" "}
      </p>
      <br />
      <p>
        3.3 Security Funambol uses industry standard security measures to
        protect against the loss, misuse and alteration of the information under
        our control. Although we make good faith efforts to protect the storage
        and transmission of your Personal Data, we cannot guarantee complete
        security. Please be aware that, whenever you voluntarily post
        information to message boards or any other public forums, such
        information can be accessed by anyone and, if such information includes
        your contact information, third parties may use it to send you
        unsolicited communications.
      </p>{" "}
      <br />
      <p>
        4. LINKS The Site may contain links to third party sites. Please be
        aware that we are not responsible for the privacy practices of such
        other sites. We encourage our users to be aware when they leave our Site
        and to read the privacy statements of other sites.{" "}
      </p>
      <br />
      <p>
        5. UPDATING PERSONAL DATA We realize that your Personal Data is subject
        to change. You may edit your Account Data at any time (provided that it
        remains accurate) and the Contacts Data and Rich Media Data that we
        store will change whenever you change it (either directly or via
        synchronization with another data source). If you wish to have Funambol
        delete your Account Data, you may terminate your account at any time. If
        you wish to delete your Contacts Data and Rich Media Data, you may do so
        at any time. In the event you terminate the account, all your Personal
        Data will be completely destroyed within 40 (forty) days and won't be
        recoverable in any way.
      </p>{" "}
      <br />
      <p>
        6. CHANGES TO THE FUNAMBOL PRIVACY STATEMENT If we change the Privacy
        Statement, we will post the new Privacy Statement on the Site with a
        link from the Site's homepage, so that you are always aware of our data
        privacy practices. Any substantive changes to the Funambol Privacy
        Statement will be posted 30 days prior to taking effect (for any
        existing users).
      </p>{" "}
      <br />
      <p>
        7. CONTACTING FUNAMBOL If you have questions about anything related to
        this Privacy Statement please contact Funambol at
        dataprotection@funambol.com.
      </p>
    </div>
  );
}

export default PrivacyPolicies;
